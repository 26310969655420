.radio-button-container {
    max-width: 1000px;
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
}

.radio-button {
    max-width: 10%;
    flex-basis: 10%;
    box-sizing: border-box;
    padding: 5px;
}
