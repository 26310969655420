.dropdowns-container {
    display: flex;
    justify-content: center;
    margin: 1% auto;
    max-width: 1400px;
}

.dropdown {
    width: 8%;
    padding: 1% 0.5%;
}
