.start-button-wrapper {
    display: flex;
    height: auto;
    background: transparent;
    padding-left: 0px;
    padding-right: 0px;
    margin: 20px auto;
    flex-flow: column;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 10;
}

.start-button-wrapper a {
    text-decoration: none;
}

.start-button {
    font-family: CSText, sans-serif;
    display: flex;
    background: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    font-size: 18px;
    border-radius: 61px;
    border: none;
    font-weight: bold;
    width: 239px;
    height: 56px;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.start-button:hover {
    background-color: red;
}
