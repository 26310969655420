.category-header-wrapper {
    padding: 5%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.category-header-text {
    width: 50%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.category-header-back {
    width: 25%;
}

.category-header-next {
    width: 25%;
}

.hidden {
    visibility: hidden;
}
