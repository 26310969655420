.header-wrapper {
  width: 99.4vw;
  margin: 0px auto;
  top: 1rem;
  display: flex;
  flex-flow: column;
  -webkit-box-align: center;
  align-items: center;
  position: absolute;
  max-width:100%;
}

.header-content {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex: 0 0 91.6667%;
  width: 91.6667%;
  margin: 0px auto;
  box-sizing: border-box;
  text-align: center;
  background: transparent;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.logo-wrapper {
  width: 120px;
}

.logo {
  font-weight: bold;
}

.help-wrapper {
  font-size: 24px;
  letter-spacing: 0px;
  cursor: default;
}

.ha-com-logo {
  width: 6rem;
  z-index: -1;
}
