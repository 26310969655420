.categories-parent {
    justify-content: center;
    text-align: center;
    margin: 10% auto;
    max-width: 1200px;
}

.category {
    padding: 0px;
}
