.name-dropdown-container {
    display: flex;
    justify-content: center;
}

.name-dropdown {
    width: 90%;
    max-width: 300px;
    padding: 20px;
}
